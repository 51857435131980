import { UNSUBSCRIBE_ROUTE } from 'src/app/constants/constants';

const pageKey = 'UNSUBSCRIBE';
const header = `${ pageKey }.HEADER`;
const content = `${ pageKey }.CONTENT`;
const errors = 'ERRORS';

export const UNSUBSCRIBE_HEADER = {
  route: UNSUBSCRIBE_ROUTE,
  name: `${ header }.TITLE`,
  title: `${ header }TITLE`,
  subtitle: `${ header }.SUBTITLE`,
  banner: {
    image: {
      url: 'unsubscribe.jpg',
      alt: 'Descripción imagen'
    },
    icon: 'email',
    firstLine: `${ header }.TITLE`,
  }
};

export const UNSUBSCRIBE_CONTENT = {
  form: {
    label: `${ content }.FORM.INPUT_LABEL`,
    button: `${ content }.FORM.SUBMIT_BUTTON`
  },
  advice: `${ content }.ADVICE`,

  unsubscribed: {
    success: `${ content }.UNSUBSCRIBED.SUCCESS`,
    previously: `${ content }.UNSUBSCRIBED.PREVIOUSLY`,
    button: `${ content }.UNSUBSCRIBED.BUTTON`,
  },
  errors: {
    checkEmail: `${ errors }.CHECK_EMAIL`,
    unknownError: `${ errors }.UNKNOWN_ERROR`,
    buttons: {
      return: `${ errors }.BUTTONS.RETURN`
    }
  }
};

