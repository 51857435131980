import { Section } from '@interfaces/landing.interface';

export const PLANVITAL_SITE = 'https://www.planvital.cl';
export const PLANVITAL_PHONE = '800 072 072';
export const SMART_PLAYER_PATH = 'assets/js/smartEmbed.js';

export const CREDENTIALS_ERRORS = 40001;
export const ENTITY_NOT_FOUND = 40002;
export const SERVER_ERROR = 50000;
export const INCORRECT_METHOD = 40003;
export const DATA_PROCESSING_ERROR = 50001;
export const ROUTE_PARAM_ERROR = 40004;

export const SOCIAL_MEDIA = [{
  url: 'https://api.whatsapp.com/send?phone=56222645200&text=Hola',
  icon: 'i-whatsapp',
}, {
  url: 'https://www.instagram.com/planvitalafp/',
  icon: 'i-instagram',
}, {
  url: 'https://www.linkedin.com/company/3659528/',
  icon: 'i-linkedin',
}, {
  url: 'https://www.youtube.com/channel/UCZOAjIwHUEJeBXaFXz5c-dA?view_as=subscriber',
  icon: 'i-youtube',
}];

export const SITES_PV = [{
  url: 'https://www.planvital.cl',
  name: 'FOOTER.SECTIONS.WEBSITES.WEBSITES.PLANVITAL',
}, {
  url: 'https://traspaso.planvital.cl/',
  name: 'FOOTER.SECTIONS.WEBSITES.WEBSITES.AFP_PLANVITAL',
}, {
  url: 'https://www.spensiones.cl/',
  name: 'FOOTER.SECTIONS.WEBSITES.WEBSITES.PENSIONS_SUPERINTENDENCY',
}];

export const SITES_INTEREST = [{
  url: 'https://planvital.cl/affiliate/assessment/multi-funds',
  name: 'FOOTER.SECTIONS.QUESTIONS.QUESTIONS.MULTIFUNDS',
}, {
  url: 'https://planvital.cl/affiliate/savings/mandatory-savings',
  name: 'FOOTER.SECTIONS.QUESTIONS.QUESTIONS.OBLIGATORY_CONTRIBUTION_ACCOUNT',
}];

export const SITES_LANDING = [
  {
    url: 'apv',
    name: 'FOOTER.SECTIONS.QUESTIONS.QUESTIONS.APV',
  }, {
    url: 'cuenta2',
    name: 'FOOTER.SECTIONS.QUESTIONS.QUESTIONS.SAVING_ACCOUNT',
  },
  {
    url: 'clave-seguridad',
    name: 'FOOTER.SECTIONS.QUESTIONS.QUESTIONS.SECURITY_KEY',
  }, {
    url: 'clave-acceso',
    name: 'FOOTER.SECTIONS.QUESTIONS.QUESTIONS.ACCESS_KEY',
  }];

// tslint:disable-next-line: max-line-length
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CELLPHONE_LENGTH = 9;
export const CHILEAN_CELLPHONE_PATTERN = /^[9]{1}[0-9]{8}$/;

export const VIDEO_ROUTE = 'video';
export const SAVINGS_ACCOUNT_ROUTE = 'cuenta2';
export const APV_ROUTE = 'apv';
export const SECURITY_KEY_ROUTE = 'clave-seguridad';
export const ACCESS_KEY_ROUTE = 'clave-acceso';
export const ACCESS_KEY_ROUTE_OLD = 'claves';
export const UNSUBSCRIBE_ROUTE = 'cancelar-suscripcion';

export const LANDING_PRODUCTS = [
  {
    route: SAVINGS_ACCOUNT_ROUTE,
    name: 'LANDING.PRODUCTS.SAVING_ACCOUNT',
    title: 'LANDING.PRODUCTS.SAVING_ACCOUNT',
    subtitle: 'LANDING.SECTIONS.SAVING_ACCOUNT.SUBTITLE',
    banner: {
      image: {
        url: 'landing-cuenta-ahorro.jpg',
        alt: 'saving account'
      },
      icon: 'bar',
      firstLine: 'LANDING.PRODUCTS.SAVING_ACCOUNT',
    }
  },
  {
    route: APV_ROUTE,
    name: 'LANDING.PRODUCTS.APV',
    title: 'LANDING.PRODUCTS.APV',
    subtitle: 'LANDING.SECTIONS.APV.SUBTITLE',
    banner: {
      image: {
        url: 'landing-apv.jpg',
        alt: 'apv'
      },
      icon: 'worldwide',
      firstLine: 'LANDING.PRODUCTS.APV',
    }
  },
  {
    route: SECURITY_KEY_ROUTE,
    name: 'LANDING.PRODUCTS.SECURITY_KEY',
    title: 'LANDING.PRODUCTS.SECURITY_KEY',
    subtitle: 'LANDING.SECTIONS.SECURITY_KEY.SUBTITLE',
    banner: {
      image: {
        url: 'landing-clave-seguridad.jpg',
        alt: 'key route'
      },
      icon: 'lock',
      firstLine: 'LANDING.PRODUCTS.SECURITY_KEY',
    }
  },
  {
    route: ACCESS_KEY_ROUTE,
    name: 'LANDING.PRODUCTS.ACCESS_KEY',
    image: 'landing-clave-acceso',
    title: 'LANDING.PRODUCTS.ACCESS_KEY',
    subtitle: 'LANDING.SECTIONS.ACCESS_KEY.SUBTITLE',
    banner: {
      image: {
        url: 'landing-clave-acceso.jpg',
        alt: 'apv'
      },
      icon: 'pig',
      firstLine: 'LANDING.PRODUCTS.ACCESS_KEY',
    }
  }
] as Array<Section>;

export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
  {
    route: 'contact',
    method: 'POST'
  }
];

export const RECAPTCHA_ACTION_KEY = 'contact';

export const LANGUAGE_KEY = 'lang';
export const LANGUAGES_ARRAY = ['es', 'ht'];
export const DEFAULT_LANG = 'es';

export const RUT_KEY = 'rut';
export const EMAIL_KEY = 'email';

export const GOOGLE_ANALYTICS_ID = 'G-KKSHX5J2B9';
