import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@providers/loading.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/internal/operators';
import { validateRut } from '../common/validators/rut.validators';
import { LOGIN_CONTENT } from '@constants/login.constants';
import { CREDENTIALS_ERRORS, EMAIL_KEY, RUT_KEY } from '@constants/constants';
import { FontService } from '@providers/font/font.service';
import { Util } from '../util/util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public pageContent = LOGIN_CONTENT;
  public loginForm: FormGroup;
  public loading = false;
  public loginSuccess = false;
  public returnUrl: string;
  public entityNameId: string;
  public videoData: any;

  constructor(
    public font: FontService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private util: Util,
  ) {
    this.entityNameId = this.route.snapshot.params.nameId;
    this.loginForm = this.formBuilder.group({
      rut: ['', [ Validators.required, validateRut ]],
    });
  }

  ngOnInit() {
    this.loadingService.hideLoading();
  }

  get form() { return this.loginForm.controls; }
  get rut() { return this.form.rut; }
  get messages() {return this.pageContent.form.controls.rut.errors; }

  public getErrorMessage(controlName: string) {
    const control = this.form[controlName];

    if (control.hasError('required') && control.touched) {
      return this.messages.required;
    }
    if (this.invalidRut(controlName)) {
      return this.messages.invalidFormat;
    }
    return '';
  }

  public login() {
    if (this.loginForm.invalid) { return; }

    this.loadingService.showLoading();
    const { rut } = this.loginForm.controls;
    const rutUpperCase = rut.value.toUpperCase();
    const password = this.util.setPassword(rutUpperCase);

    this.authenticationService.login(rutUpperCase, password, this.entityNameId)
      .pipe(first())
      .subscribe(
        (data: any) => {
          const { videoParams, siteData } = data;
          this.videoData = videoParams;
          this.saveUserData(siteData);
          this.loadingService.hideLoading();
          this.loginSuccess = true;
        },
        (error) => {
          this.loadingService.hideLoading();
          this.handleError(error);
        }
      );
  }

  private invalidRut(controlName: string) {
    const control = this.form[controlName];
    return control.invalid && control.dirty && controlName === 'rut';
  }

  private handleError(errorData) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      panelClass: 'alert-modal',
      data: {
        ...errorData.error,
        primaryButtonText: 'Regresar',
        icon: 'i-warning',
      }
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.loading = false;
        if (errorData.error.code === CREDENTIALS_ERRORS) { this.loginForm.reset(); }
      });
  }

  private saveUserData(siteData: any) {
    const { rut, email } = siteData;
    if (rut) { sessionStorage.setItem(RUT_KEY, rut); }
    if (email) { sessionStorage.setItem(EMAIL_KEY, email); }
  }
}
