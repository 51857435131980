<div class="footer-container">
  <app-superintendence></app-superintendence>
  <div class="footer general-container">
    <div class="columns">
      <div class="section">
        <p class="column-title" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.questions.title | translate"></p>
        <p class="link" *ngFor="let site of sitesInterest"><a [ngClass]="font?.size" href="{{ site.url }}" >{{ site.name | translate }}</a></p>
      </div>
      <div class="section">
        <p class="column-title" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.questions.title2 | translate"></p>
        <p class="link" *ngFor="let site of sitesLanding"><a [ngClass]="font?.size" href="{{ translate.currentLang + '/' + site.url }}" >{{ site.name | translate }}</a></p>
      </div>
      <div class="section">
        <p class="column-title" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.websites.title | translate"></p>
        <p class="link" *ngFor="let site of sitesPlanVital"><a [ngClass]="font?.size" href="{{ site.url }}">{{ site.name | translate }}</a></p>
      </div>
    </div>
    <div class="contact-container">
      <a href="tel:{{ contactCenterNumber }}">
        <div class="contact-center" [ngClass]="font?.size">
          <div class="i-phone icon"></div>
          <p [innerHTML]="pageContent?.sections.contactCenter.title | translate"></p> 
          <span class="contact-number">{{ contactCenterNumber }}</span>
        </div>
      </a>
      <div class="social-medias">
        <div class="icon" [ngClass]="social.icon" (click)="navigate.goTo(social.url)" *ngFor="let social of socialMedia"></div>
      </div>
    </div>
    <div class="certificates">
      <p class="disclaimer" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.certificates.disclaimer | translate"></p>
      <div class="certificate-container">
        <img class="logo" *ngFor="let certificate of certificates" src="{{ certificate?.url }}" alt="{{ certificate?.alt }}"/>
      </div>
    </div>
  </div>
</div>
