const pageKey = 'HEADER';

export const HEADER_CONTENT = {
  title: `${pageKey}.TITLE`,
  options: {
    primary: {
      planVitalSite: `${pageKey}.OPTIONS.PRIMARY.PLAN_VITAL_SITE`
    }
  }
};

export const HEADER_LOGO = {
  es: '../../assets/img/logo-title.svg',
  ht: '../../assets/img/logo-title-ht.svg'
};

export const LANGUAGES_MAP = {
  es: 'Español',
  ht: 'Creolé',
};
