import { Component } from '@angular/core';
import { LANGUAGES_ARRAY } from '@constants/constants';
import { HeaderComponent } from '@components/header/header.component';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent extends HeaderComponent {
  public langsArray = LANGUAGES_ARRAY;
}
