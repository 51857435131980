<div class="header-desktop-container">
  <div class="logo i-pv-white-logo"></div>
  <div class="options-secondary">
    <div class="options-secondary-container general-container">
      <select #langSelect class="option select button" [ngClass]="font?.size" [value]="translate.currentLang" (change)="selectLang($event.target.value)">
        <option *ngFor="let lang of languages | keyvalue" [value]="lang.key">{{ lang.value }}</option>
      </select>
      <button class="option button letter" [ngClass]="font?.size" (click)="changeFontSize('bigger')">A+</button>
      <button class="option button letter" [ngClass]="font?.size" (click)="changeFontSize('smaller')">A-</button>
    </div>
  </div>
  <div class="options-primary general-container">
    <button (click)="navigate.goTo(planVitalSite)" class="option button" [ngClass]="font?.size" 
    [innerHTML]="pageContent?.options.primary.planVitalSite | translate"></button>
  </div>
</div>
