<div class="container">
  <div class="section-banner">
    <app-banner [banner]="header.banner"></app-banner>
  </div>
  <div class="tabs">
    <app-tab-navigation (tabSelected)="null" [activeTab]="nav[0]" [tabs]="nav"></app-tab-navigation>
  </div>

  <div class="general-container">
    <div *ngIf="isSubscribed && !loading" class="unsubscribe-container">
      <h2 [ngClass]="font?.size" class="title">{{ header.subtitle | translate }}</h2>
      <form (ngSubmit)="unsubscribe()" [formGroup]="unsubscribeForm" class="form">
        <mat-form-field [ngClass]="font?.size" class="input-container" floatLabel="always">
          <mat-label [innerHTML]="pageContent?.form.label | translate"></mat-label>
          <input [ngClass]="font?.size" formControlName="mail" matInput placeholder="correo@planvital.cl" type="text">
          <span class="i-validator" matSuffix></span>
        </mat-form-field>
        <button [disabled]="unsubscribeForm.invalid" [innerHTML]="pageContent?.form.button | translate"
                [ngClass]="font?.size" class="button primary"
                type="submit"></button>
      </form>
    </div>

    <div *ngIf="!isSubscribed" class="unsubscribe-container">
      <h2 [ngClass]="font?.size"
          class="title">{{ (previously ? pageContent.unsubscribed.previously : pageContent.unsubscribed.success) | translate }}</h2>
      <button [innerHTML]="pageContent?.unsubscribed.button | translate"
              [ngClass]="font?.size" class="button primary"
              (click)="goToMyVideo()"></button>
    </div>
    <div class="advice" [ngClass]="font?.size">{{ pageContent.advice | translate }}</div>
  </div>
</div>
