const pageKey = 'LOGIN';

export const LOGIN_CONTENT = {
  header: {
    title: `${pageKey}.HEADER.TITLE`,
    description: `${pageKey}.HEADER.DESCRIPTION`,
  },
  form: {
    title: 'HEADER.TITLE',
    controls: {
      rut: {
        label: `${pageKey}.FORM.CONTROLS.RUT.LABEL`,
        errors: {
          required: `${pageKey}.FORM.CONTROLS.RUT.ERRORS.REQUIRED`,
          invalidFormat: `${pageKey}.FORM.CONTROLS.RUT.ERRORS.INVALID_FORMAT`
        }
      },
      password: {
        label: `${pageKey}.FORM.CONTROLS.PASSWORD.LABEL`
      }
    },
    buttons: {
      login: `${pageKey}.FORM.BUTTONS.LOGIN`
    },
    messages: {
      password: `${pageKey}.FORM.MESSAGES.PASSWORD`
    }
  }
};
