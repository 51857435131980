import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SITES_PV } from '@constants/constants';
import { HEADER_CONTENT, LANGUAGES_MAP } from '@constants/header.constants';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public languages = LANGUAGES_MAP;
  public pageContent = HEADER_CONTENT;
  public planVitalSite = SITES_PV[0].url;
  constructor(
    public font: FontService,
    public translate: TranslateService,
    public navigate: NavigationService,
    public location: Location,
    public router: Router,
  ) {}

  public changeFontSize(change: 'bigger' | 'smaller') {
    this.font.setSize(change);
  }

  public selectLang(selectedLang: string) {
    const currentLang = this.translate.currentLang;
    this.location.go(this.router.url.replace('/' + currentLang, '/' +  selectedLang));
    this.translate.use(selectedLang);
  }
}
