import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public goTo(url: string) {
    window.open(url);
  }
}
