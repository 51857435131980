import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseURL;
  }
  login(rut: string, password: string, nameId: string) {
    return this.http.post(`${this.baseUrl}/${nameId}`, { rut, password })
      .pipe(catchError(error => throwError(error)));
  }
}
