import { NgModule } from '@angular/core';
import { FormatRutDirective } from './format-rut/format-rut.directive';
import { FormatRutLowercaseDirective } from './format-rut-lowercase/format-rut-lowercase.directive';

@NgModule({
  declarations: [
    FormatRutDirective,
    FormatRutLowercaseDirective,
  ],
  exports: [
    FormatRutDirective,
    FormatRutLowercaseDirective,
  ],
  providers: [
  ]
})
export class DirectivesModule {}
