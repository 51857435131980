import { Component } from '@angular/core';
import { SUPERINTENDENCE } from '@constants/footer.constans';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-superintendence',
  templateUrl: './superintendence.component.html',
  styleUrls: ['./superintendence.component.scss'],
})
export class SuperintendenceComponent {
  public superintendenceText = SUPERINTENDENCE.text;
  public superintendenceLink = SUPERINTENDENCE.link;
  public superintendenceUrl = SUPERINTENDENCE.url;

  constructor(
    public font: FontService,
    public navigate: NavigationService
  ) {
  }
}
