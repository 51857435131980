const pageKey = 'LANDING';

export const LANDING_CONTENT = {
  header: {
    title: `${pageKey}.HEADER.TITLE`
  },
  products: {
    savingAccount: `${pageKey}.SELECTORS.SAVING_ACCOUNT`,
    apv: `${pageKey}.SELECTORS.APV`,
    securityKey: `${pageKey}.SELECTORS.SECURITY_KEY`,
    accessKey: `${pageKey}.SELECTORS.ACCESS_KEY`
  },
  sections: {
    savingAccount: {
      description: `${pageKey}.SECTIONS.SAVING_ACCOUNT.DESCRIPTION`,
      questions: {
        benefits: {
          question: `${pageKey}.SECTIONS.SAVING_ACCOUNT.QUESTIONS.BENEFITS.QUESTION`,
          answer: {
            freeDrafts: `${pageKey}.SECTIONS.SAVING_ACCOUNT.QUESTIONS.BENEFITS.ANSWER.FREE_DRAFTS`,
            taxBenefits: `${pageKey}.SECTIONS.SAVING_ACCOUNT.QUESTIONS.BENEFITS.ANSWER.TAX_BENEFITS`,
            multifunds: `${pageKey}.SECTIONS.SAVING_ACCOUNT.QUESTIONS.BENEFITS.ANSWER.MULTIFUNDS`
          }
        },
      },
      conditions: `${pageKey}.SECTIONS.SAVING_ACCOUNT.CONDITIONS`
    },
    apv: {
      description: `${pageKey}.SECTIONS.APV.DESCRIPTION`,
      regimes: {
        a: {
          title: `${pageKey}.SECTIONS.APV.REGIMES.A.TITLE`,
          description: `${pageKey}.SECTIONS.APV.REGIMES.A.DESCRIPTION`
        },
        b: {
          title: `${pageKey}.SECTIONS.APV.REGIMES.B.TITLE`,
          description: `${pageKey}.SECTIONS.APV.REGIMES.B.DESCRIPTION`
        },
      },
      questions: {
        benefits: {
          question: `${pageKey}.SECTIONS.APV.QUESTIONS.BENEFITS.QUESTION`,
          answer: {
            commission: `${pageKey}.SECTIONS.APV.QUESTIONS.BENEFITS.ANSWER.COMMISSION`,
            bonus: `${pageKey}.SECTIONS.APV.QUESTIONS.BENEFITS.ANSWER.BONUS`,
            backup: `${pageKey}.SECTIONS.APV.QUESTIONS.BENEFITS.ANSWER.BACKUP`,
            pension: `${pageKey}.SECTIONS.APV.QUESTIONS.BENEFITS.ANSWER.PENSION`
          }
        }
      },
      conditions: `${pageKey}.SECTIONS.APV.CONDITIONS`,
      indication: `${pageKey}.SECTIONS.APV.INDICATION`,
    },
    securityKey: {
      questions: {
        passwordFunctionality: {
          question: `${pageKey}.SECTIONS.SECURITY_KEY.QUESTIONS.PASSWORD_FUNCTIONALITY.QUESTION`,
          answer: `${pageKey}.SECTIONS.SECURITY_KEY.QUESTIONS.PASSWORD_FUNCTIONALITY.ANSWER`
        },
        getPassword: {
          question: `${pageKey}.SECTIONS.SECURITY_KEY.QUESTIONS.GET_PASSWORD.QUESTION`,
          answer: `${pageKey}.SECTIONS.SECURITY_KEY.QUESTIONS.GET_PASSWORD.ANSWER`,
          indication: `${pageKey}.SECTIONS.SECURITY_KEY.QUESTIONS.GET_PASSWORD.INDICATION`
        }
      }
    },
    accessKey: {
      questions: {
        passwordFunctionality: {
          question: `${pageKey}.SECTIONS.ACCESS_KEY.QUESTIONS.PASSWORD_FUNCTIONALITY.QUESTION`,
          answer: `${pageKey}.SECTIONS.ACCESS_KEY.QUESTIONS.PASSWORD_FUNCTIONALITY.ANSWER`
        },
        getPassword: {
          question: `${pageKey}.SECTIONS.ACCESS_KEY.QUESTIONS.GET_PASSWORD.QUESTION`,
          answer: `${pageKey}.SECTIONS.ACCESS_KEY.QUESTIONS.GET_PASSWORD.ANSWER`
        }
      }
    },
    contactUs: {
      title: `${pageKey}.SECTIONS.CONTACT_US.TITLE`,
      form: {
        controls: {
          rut: {
            label: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.RUT.LABEL`,
            errors: {
              required: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.RUT.ERRORS.REQUIRED`,
              invalidFormat: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.RUT.ERRORS.INVALID_FORMAT`
            }
          },
          cellphone: {
            label: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.CELLPHONE.LABEL`,
            errors: {
              invalidLength: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.CELLPHONE.ERRORS.INVALID_LENGTH`,
              invalidFormat: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.CELLPHONE.ERRORS.INVALID_FORMAT`
            }
          },
          email: {
            label: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.EMAIL.LABEL`,
            errors: {
              invalidFormat: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.EMAIL.ERRORS.INVALID_FORMAT`
            }
          },
          products: {
            title: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.PRODUCTS.TITLE`,
            label: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.PRODUCTS.LABEL`,
            errors: {
              required: `${pageKey}.SECTIONS.CONTACT_US.FORM.CONTROLS.PRODUCTS.ERRORS.REQUIRED`,
            }
          },
        },
        buttons: {
          send: `${pageKey}.SECTIONS.CONTACT_US.FORM.BUTTONS.SEND`
        },
        captcha: {
          paragraph: `${pageKey}.SECTIONS.CONTACT_US.FORM.CAPTCHA.PARAGRAPH`,
          andText: `${pageKey}.SECTIONS.CONTACT_US.FORM.CAPTCHA.AND_TEXT`,
          googleAplican: `${pageKey}.SECTIONS.CONTACT_US.FORM.CAPTCHA.GOOGLE_APLICAN`,
          privacy: `${pageKey}.SECTIONS.CONTACT_US.FORM.CAPTCHA.PRIVACY`,
          conditions: `${pageKey}.SECTIONS.CONTACT_US.FORM.CAPTCHA.CONDITIONS`,
        },
        modal: {
          success: {
            message: `${pageKey}.SECTIONS.CONTACT_US.FORM.MODAL.SUCCESS.MESSAGE`,
            messageDescription: `${pageKey}.SECTIONS.CONTACT_US.FORM.MODAL.SUCCESS.MESSAGE_DESCRIPTION`,
            button: `${pageKey}.SECTIONS.CONTACT_US.FORM.MODAL.SUCCESS.BUTTON`,
          },
          failure: {
            message: `${pageKey}.SECTIONS.CONTACT_US.FORM.MODAL.FAILURE.MESSAGE`,
            messageDescription: `${pageKey}.SECTIONS.CONTACT_US.FORM.MODAL.FAILURE.MESSAGE_DESCRIPTION`,
            button: `${pageKey}.SECTIONS.CONTACT_US.FORM.MODAL.FAILURE.BUTTON`,
          }
        }
      }
    },
    actions: {
      seeMore: `${pageKey}.SECTIONS.ACTIONS.SEE_MORE`,
      seeLess: `${pageKey}.SECTIONS.ACTIONS.SEE_LESS`,
    }
  },
  careChannels: {
    pv: `${pageKey}.CARE_CHANNELS.PLANVITAL`,
    contactCenter: `${pageKey}.CARE_CHANNELS.CONTACT_CENTER`,
    app: `${pageKey}.CARE_CHANNELS.APP`,
    socialNetworks: `${pageKey}.CARE_CHANNELS.SOCIAL_NETWORKS`
  }
};
