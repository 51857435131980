<div class="container">
    <div class="login-container" *ngIf="!loginSuccess">
      <div class="login-title-container">
        <h2 class="title" [ngClass]="font?.size" [innerHTML]="pageContent?.header.title | translate"></h2>
        <p class="description" [ngClass]="font?.size" [innerHTML]="pageContent?.header.description | translate"></p>
      </div>
      <div class="form-container">
        <div class="title form" [ngClass]="font?.size">
          <p [innerHTML]="pageContent?.form.title | translate"></p>
        </div>
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
          <mat-form-field class="input-container" floatLabel="always" [ngClass]="font?.size">
            <mat-label [innerHTML]="pageContent?.form.controls.rut.label | translate"></mat-label>
            <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="12.345.678-9" [formatRutLowercase]="rut" maxLength="12">
            <span matSuffix class="i-validator"></span>
            <mat-error [ngClass]="font?.size">{{ getErrorMessage('rut') | translate }}</mat-error>
          </mat-form-field>
          <button class="button primary" [ngClass]="font?.size" type="submit" [disabled]="loginForm.invalid" [innerHTML]="pageContent?.form.buttons.login | translate"></button>
        </form>
      </div>
    </div>
    <app-video *ngIf="loginSuccess" [videoData]="videoData" ></app-video>
  </div>
