<div class="modal-close">
  <div class="i-close-modal" (click)="dialogClose()"></div>
</div>
<div class="modal-container">
  <div class="icon-result" [ngClass]="data.icon"></div>

  <p class="title" [ngClass]="font?.size">{{ data.message | translate }}</p>

  <p class="subtitle" [ngClass]="font?.size" *ngIf="data.messageDescription">{{ data.messageDescription | translate }}</p>
  <div class="button-container">
    <button class="button primary" [ngClass]="font?.size" (click)="primaryAction()">{{ data.primaryButtonText | translate }}</button>
    <button class="button alternative" [ngClass]="font?.size" (click)="secondaryAction()"
      *ngIf="data.secondaryButtonText">{{ data.secondaryButtonText }}</button>
  </div>
</div>
