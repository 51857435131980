export const environment = {
  name: 'qa',
  baseURL: 'https://us-east4-plan-vital-dev-qa.cloudfunctions.net/statements-video-data',
  orchestratorURL: 'http://34.120.156.248/transfer/',
  proxyApiUrl: '',
  production: false,
  INDIVIDEO_ENVIRONMENT: 'UAT',
  INDIVIDEO_CODE: '2sELxdTtu2FVw-948',
  INDIVIDEO_CODE_HT: 'WGA3ppaSFKAGWYDfJ7Vw-746',
  RECAPTCHA_SITE_KEY: '6LeoVP0ZAAAAAPvWIJ8yZvU28NlmRvt8xyW0V2lk',
};
