import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() icon: string;
  @Input() description: string;

  constructor(
    public font: FontService,
    public translate: TranslateService,
  ) {}
}
