import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private orchestratorURL: string;

  constructor(private http: HttpClient) {
    this.orchestratorURL = environment.orchestratorURL;
  }
  public sendContact(contactData) {
    return this.http.post(`${this.orchestratorURL}contacts/account-statements`, contactData)
      .pipe(catchError(error => throwError(error)));
  }
}
