import { AbstractControl } from '@angular/forms';
import { validate } from 'rut.js';

export function validateRut(control: AbstractControl) {
  const parent = control.parent;
  if (!parent) {
    return { isError: true };
  }
  const rut = parent.controls['rut'];
  if (!validate(rut.value)) {
    return { isError: true };
  }
  return null;
}
