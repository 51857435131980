const pageKey = 'FOOTER';

export const FOOTER_CONTENT = {
  sections: {
    questions: {
      title: `${pageKey}.SECTIONS.QUESTIONS.TITLE`,
      title2: `${pageKey}.SECTIONS.QUESTIONS.TITLE2`,
      questions: {
        multifunds: `${pageKey}.SECTIONS.QUESTIONS.QUESTIONS.MULTIFUNDS`,
        obligatoryAccount: `${pageKey}.SECTIONS.QUESTIONS.QUESTIONS.OBLIGATORY_CONTRIBUTION_ACCOUNT`,
        apv: `${pageKey}.SECTIONS.QUESTIONS.QUESTIONS.APV`,
        savingAccount: `${pageKey}.SECTIONS.QUESTIONS.QUESTIONS.SAVING_ACCOUNT`,
        accessCode: `${pageKey}.SECTIONS.QUESTIONS.QUESTIONS.ACCESS_CODE`
      }
    },
    websites: {
      title: `${pageKey}.SECTIONS.WEBSITES.TITLE`,
      websites: {
        pv: `${pageKey}.SECTIONS.WEBSITES.WEBSITES.PLANVITAL`,
        afpPv: `${pageKey}.SECTIONS.WEBSITES.WEBSITES.AFP_PLANVITAL`,
        pensionsSuperintendency: `${pageKey}.SECTIONS.WEBSITES.WEBSITES.PENSIONS_SUPERINTENDENCY`
      }
    },
    socialNetworks: {
      title: `${pageKey}.SECTIONS.SOCIAL_NETWORKS.TITLE`
    },
    contactCenter: {
      title: `${pageKey}.SECTIONS.CONTACT_CENTER.TITLE`
    },
    certificates: {
      disclaimer: `${pageKey}.SECTIONS.CERTIFICATES.DISCLAIMER`
    }
  }
};

export const CERTIFICATES = [
  {
    url: 'assets/img/PLE.png',
    alt: 'Plain Language Europe',
  },
  {
    url: 'assets/img/fundacion_visibilia.png',
    alt: 'Fundación Visibilia',
  },
  {
    url: 'assets/icons/i-bh.svg',
    alt: 'BH Compliance',
  },
];

export const SUPERINTENDENCE = {
  text: 'FOOTER.SECTIONS.SUPERINTENDENCE.TEXT',
  link: 'FOOTER.SECTIONS.SUPERINTENDENCE.LINK',
  url: 'https://es.research.net/r/spensiones',
};
