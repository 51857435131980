import { Component, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IndiVideoEvent, IndiVideoEventNameEnum } from './indi-video.event';

declare var window: any;
declare const BEM: any;
declare const BluePlayer: any;

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'indi-video',
  template: '',
})
/* istanbul ignore next */
export class IndiVideoComponent implements OnInit {

  @Input() videoSrc: string;
  @Input() clientID: string;
  @Input() videoCode: string;
  @Input() videoLanguage: string;
  @Input() autoPlayerInit: boolean;
  @Input() autoPlayerPlay: boolean;
  @Input() emulateFullscreen: boolean;
  @Input() environment: string;
  @Input() autoFullScreen: boolean;
  @Input() lockFullScreenToLandscape: boolean;
  @Input() analyticsCode: string;
  @Input() analyticsClientCode: string;
  @Input() useBluerushBranding: boolean;
  @Input() withSubtitle: boolean;
  @Input() withIndivideoLogo: boolean;
  @Input() googleAnalyticsId: string;

  @Output() emitEvent: EventEmitter<IndiVideoEvent> = new EventEmitter<IndiVideoEvent>();

  private smartPlayer: any;
  private iframe: HTMLElement;
  private parentDiv: HTMLElement;
  private imageElement: HTMLElement;

  constructor(private element: ElementRef) {
    this.videoLanguage = 'es-cl';
    this.autoPlayerInit = false;
    this.emulateFullscreen = false;
    this.autoFullScreen = true;
    this.autoPlayerPlay = false;
    this.lockFullScreenToLandscape = false;
    this.useBluerushBranding = false;
    this.withSubtitle = false;
    this.withIndivideoLogo = false;
  }

  public ngOnInit(): void {
    window.individeoOnReady = this.individeoOnReady.bind(this);
    this.addScriptTag();
  }

  public initIndiVideoWithData(personalizedData: any) {
    if (this.smartPlayer) { this.smartPlayer.initIndivideo(personalizedData); }
  }

  public play() {
    if (this.smartPlayer) { this.smartPlayer.play(); }
  }

  public replay() {
    if (this.smartPlayer) { this.smartPlayer.replay(); }
  }

  public pause() {
    if (this.smartPlayer) { this.smartPlayer.pause(); }
  }

  public reset() {
    if (this.smartPlayer) { this.smartPlayer.reset(); }
  }

  public stop() {
    if (this.smartPlayer) { this.smartPlayer.stop(); }
  }

  public individeoOnReady(smartPlayer: any) {
    this.smartPlayer = smartPlayer;
    this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONREADY, player: smartPlayer });
    this.bindAllVideoEvents();
    window.individeoOnReady = false;
    this.setVideoElements();
  }

  /* istanbul ignore next */
  private bindAllVideoEvents() {
    BEM.bind(BluePlayer.ONFULLSCREENENTER, function(e, player) {
      if (this.lockFullScreenToLandscape) { (screen.orientation as any).lock('landscape'); }
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONFULLSCREENENTER, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONFULLSCREENEXIT, function(e, player) {
      if (this.lockFullScreenToLandscape) { screen.orientation.unlock(); }
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONFULLSCREENEXIT, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONPLAY, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONPLAY, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONSTART, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONSTART, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONPAUSE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONPAUSE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONMUTE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONMUTE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONUNMUTE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONUNMUTE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONLOADPROGRESS, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONLOADPROGRESS, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONLOADCOMPLETE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONLOADCOMPLETE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONMEDIACHANGE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONMEDIACHANGE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONFIRSTQUARTILECOMPLETE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONFIRSTQUARTILECOMPLETE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONMIDPOINTCOMPLETE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONMIDPOINTCOMPLETE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONTHIRDQUARTILECOMPLETE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONTHIRDQUARTILECOMPLETE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONPLAYCOMPLETE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONPLAYCOMPLETE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONPLAYINCOMPLETE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONPLAYINCOMPLETE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONMETADATA, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONMETADATA, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONPRELOADSTART, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONPRELOADSTART, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONPRELOADEND, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONPRELOADEND, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONTIME, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONTIME, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONSEEKSTART, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONSEEKSTART, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONSEEK, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONSEEK, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONSEEKEND, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONSEEKEND, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONBUFFERSTART, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONBUFFERSTART, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONBUFFEREND, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONBUFFEREND, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONERROR, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONERROR, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONCLOSEDCAPTIONENTER, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONCLOSEDCAPTIONENTER, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONCLOSEDCAPTIONEXIT, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONCLOSEDCAPTIONEXIT, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONCLOSEDCAPTIONLOADCOMPLETED, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONCLOSEDCAPTIONLOADCOMPLETED, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONCLOSEDCAPTIONREADY, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONCLOSEDCAPTIONREADY, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONVOLUMECHANGE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONVOLUMECHANGE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONCONTROLERHIDE, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONCONTROLERHIDE, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONCONTROLERSHOW, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONCONTROLERSHOW, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONSTOP, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONSTOP, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONDESTROY, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONDESTROY, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONENTERFRAME, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONENTERFRAME, player});
    }.bind(this));
    BEM.bind(BluePlayer.ONMEDIACTA, function(e, player) {
      this.emitEvent.emit({ name: IndiVideoEventNameEnum.ONMEDIACTA, player});
    }.bind(this));
  }

  private addScriptTag() {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = this.videoSrc;
    if (this.videoLanguage) { scriptTag.setAttribute('data-bp-lang', this.videoLanguage); }
    if (this.analyticsCode) { scriptTag.setAttribute('data-bp-ga', this.analyticsCode); }
    if (this.analyticsClientCode) { scriptTag.setAttribute('data-bp-gac', this.analyticsClientCode); }
    scriptTag.setAttribute('data-bp-attachment-code', this.videoCode);
    scriptTag.setAttribute('data-bp-recipient-key', this.clientID);
    scriptTag.setAttribute('data-bp-enable-emulate-fullscreen', String(this.emulateFullscreen));
    scriptTag.setAttribute('data-bp-auto-init', String(this.autoPlayerInit));
    scriptTag.setAttribute('data-bp-auto-play', String(this.autoPlayerPlay));
    scriptTag.setAttribute('data-bp-auto-fullscreen', String(this.autoFullScreen));
    scriptTag.setAttribute('data-bp-on-ready', 'individeoOnReady');
    scriptTag.setAttribute('data-bp-use-branding', String(this.useBluerushBranding));
    scriptTag.setAttribute('data-bp-is-subtitle', String(this.withSubtitle));
    scriptTag.setAttribute('data-bp-enable-company-signature', String(this.withIndivideoLogo));
    scriptTag.setAttribute('data-bp-ga-id', String(this.googleAnalyticsId));
    this.element.nativeElement.appendChild(scriptTag);
  }

  private setVideoElements() {
    this.iframe = document.querySelector('iframe');
    this.parentDiv = this.iframe.parentElement;
    this.imageElement = this.iframe.nextElementSibling as HTMLElement;
    this.setMediaQueries();
  }

  private setMediaQueries() {
    const media = window.matchMedia('(max-width: 900px)');
    this.setMediaQueryMatch(media);
    media.addListener(this.setMediaQueryMatch.bind(this));
  }

  private setMediaQueryMatch(media) {
    media.matches ? this.setVideoStyles('mobile') : this.setVideoStyles('desktop');
  }

  private setVideoStyles(type: string) {
    this.iframe.style.position = 'absolute';
    this.iframe.style.top = '0';
    this.iframe.style.left = type === 'mobile' ? '0' : '11%';
    this.iframe.style.width = type === 'mobile' ? '100vw' : '80vw';
    this.iframe.style.height = type === 'mobile' ? '56.25vw' : '45vw';
    this.parentDiv.style.paddingBottom = type === 'mobile' ? '56.25%' : '0%';
    this.parentDiv.style.backgroundColor = 'white';
    this.imageElement.style.display = 'none';
  }

}
