import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Section } from '@interfaces/landing.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})
export class TabNavigationComponent {
  @Input() public tabs: Section[];
  @Input() public activeTab: Section;
  @Output() tabSelected = new EventEmitter<Section>();

  constructor(
    public font: FontService,
  ) {
  }

  public selectTab(product: Section) {
    this.tabSelected.emit(product);
  }
}
