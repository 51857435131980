<div class="container">
  <div class="video-container">
    <div class="video-title">
        <h2 class="title" [ngClass]="font?.size">Cartola Mensual</h2>
    </div>
    <div class="video-content">
      <ng-container *ngIf="!reRender">
        <indi-video #indiVideo [environment]="indiVideoEnv" [videoSrc]="indiVideoSrc" [clientID]="videoData.clientID" [videoCode]="indiVideoCode" [googleAnalyticsId]="googleAnalyticsId" (emitEvent)="onVideoEvent($event)">
        </indi-video>
      </ng-container>
    </div>
  </div>
</div>
