import { Component } from '@angular/core';
import { HeaderComponent } from '@components/header/header.component';
import { LANDING_PRODUCTS } from '@constants/constants';
import { Section } from '@interfaces/landing.interface';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent extends HeaderComponent {
  public opened = false;
  public landingProducts = LANDING_PRODUCTS;

  public goTo(product: Section) {
    const prefix = this.translate.currentLang + '/';
    this.router.navigateByUrl(prefix + product.route);
  }
}
