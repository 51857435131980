import { Component, Input, OnInit } from '@angular/core';
import { Banner, BannerStyle, BannerType } from '@interfaces/banner.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent implements OnInit {
  @Input() public banner: Banner;
  @Input() public type: BannerType;
  @Input() public style: BannerStyle;

  constructor(
    public font: FontService,
  ) { }

  ngOnInit() {
    this.setHighlightedLine();
  }

  private setHighlightedLine() {
    if (!this.banner || this.banner.highlightedLine ) { return; }
    this.banner.highlightedLine = 'highlighted-second';
  }

  public get styleClass() {
    return this.style || '';
  }

  public get iconClass() {
    return this.banner ? `i-${this.banner.icon}` : '';
  }

  public get infoBannerClass() {
    if (!this.banner) { return ''; }
    const { highlightedLine } = this.banner;
    return `${highlightedLine || ''} ${this.type || ''}`;
  }

}
