
<mat-sidenav-container class="header-mobile-container" [class.open-side-menu]="opened">
  <mat-sidenav #sidenav mode="over" position="end" [(opened)]="opened">
    <div class="side-menu-container">
      <div class="side-menu">
        <div class="options primary">
          <div class="option button transparent" [ngClass]="font?.size" *ngFor="let product of landingProducts" (click)="[sidenav.toggle(), goTo(product)]">
            {{ product.title | translate }}
          </div>
          <div (click)="navigate.goTo(planVitalSite)" class="option button" [ngClass]="font?.size" 
          [innerHTML]="pageContent?.options.primary.planVitalSite | translate"></div>
          <div class="footer-options">
            <div class="accessibility-options">
              <select #langSelect class="option select button" [ngClass]="font?.size" [value]="translate.currentLang" (change)="selectLang($event.target.value)" >
                <option *ngFor="let lang of languages | keyvalue" [value]="lang.key">{{ lang.value }}</option>
              </select>
              <button class="option button letter" [ngClass]="font?.size" (click)="changeFontSize('bigger')">A+</button>
              <button class="option button letter" [ngClass]="font?.size" (click)="changeFontSize('smaller')">A-</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="header-mobile">
      <div class="toolbar">
        <div class="icon i-account disabled"></div>
        <div class="logo i-pv-white-logo"></div>
        <div (click)="opened = true" class="icon i-menu"></div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
