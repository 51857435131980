import { Directive, HostListener, Input } from '@angular/core';
import { format } from 'rut.js';
import { FormControl } from '@angular/forms';

@Directive({
  /* eslint-disable-next-line @angular-eslint/directive-selector */
  selector: '[formatRut]'
})
export class FormatRutDirective {

  @Input('formatRut') formControl: FormControl;

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    if (typedValue) {
      const rut = format(typedValue);
      this.formControl.setValue(rut);
    }
  }
}
