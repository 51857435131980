import { Component, Output, EventEmitter, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { GOOGLE_ANALYTICS_ID, LANGUAGE_KEY, SMART_PLAYER_PATH } from '@constants/constants';
import { IndiVideoComponent } from '../../components/indi-video/indi-video.component';
import { IndiVideoEvent, IndiVideoEventNameEnum } from '../../components/indi-video/indi-video.event';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {

  @Input() videoData: any;
  @Output() addEvent: EventEmitter<any>;
  @Output() finishLoadingEvent: EventEmitter<any>;
  @ViewChild('indiVideo') indiVideo: IndiVideoComponent;
  indiVideoSrc: string;
  indiVideoCode: string;
  indiVideoEnv: string;
  clientID: string;
  reRender = false;
  public googleAnalyticsId = GOOGLE_ANALYTICS_ID;

  constructor(
    public translate: TranslateService,
    public font: FontService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.addEvent = new EventEmitter();
    this.finishLoadingEvent = new EventEmitter();
    this.indiVideoSrc = SMART_PLAYER_PATH;
    this.indiVideoEnv = environment.INDIVIDEO_ENVIRONMENT;
    const lang = sessionStorage.getItem(LANGUAGE_KEY);
    this.indiVideoCode = lang === 'es' ? environment.INDIVIDEO_CODE : environment.INDIVIDEO_CODE_HT;
    this.onChangeLang();
  }

  public onVideoEvent(params: IndiVideoEvent) {
    if (params.name === IndiVideoEventNameEnum.ONREADY) {
      this.initializeVideo();
    } else if (params.name === IndiVideoEventNameEnum.ONPRELOADEND) {
      this.finishLoading();
    }
  }

  private doRerender() {
    const lang = sessionStorage.getItem(LANGUAGE_KEY);
    this.indiVideoCode = lang === 'es' ? environment.INDIVIDEO_CODE : environment.INDIVIDEO_CODE_HT;
    this.reRender = true;
    this.cdRef.detectChanges();
    this.reRender = false;
  }

  private onChangeLang() {
    this.translate.onLangChange.subscribe(() => {
      this.doRerender();
    });
  }

  private initializeVideo() {
    this.indiVideo.initIndiVideoWithData(this.videoData);
  }

  public finishLoading() {
    this.finishLoadingEvent.emit(true);
  }

  public openContactSimulationModal() { this.addEvent.emit(); }

}
