<div class="contact-box">
  <p class="title form" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.contactUs.title | translate"></p>
  <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="send()">
    <mat-form-field floatLabel="always" [ngClass]="font?.size">
      <mat-label [innerHTML]="pageContent?.sections.contactUs.form.controls.rut.label | translate"></mat-label>
      <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut" maxLength="12" required>
      <span matSuffix class="i-validator"></span>
      <mat-error [ngClass]="font?.size">{{ getErrorMessage('rut') | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" [ngClass]="font?.size">
      <mat-label [innerHTML]="pageContent?.sections.contactUs.form.controls.email.label | translate"></mat-label>
      <input [ngClass]="font?.size" matInput type="email" formControlName="email" placeholder="mail@mail.com" [pattern]="emailPattern">
      <span matSuffix *ngIf="this.contactForm.value['email']" class="i-validator"></span>
      <mat-error [ngClass]="font?.size">{{ getErrorMessage('email') | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" [ngClass]="font?.size">
      <mat-label [innerHTML]="pageContent?.sections.contactUs.form.controls.cellphone.label | translate"></mat-label>
      <input [ngClass]="font?.size" matInput type="tel" formControlName="cellphone" [placeholder]="987654321"
      [maxLength]="cellphoneLength" [minLength]="cellphoneLength" [pattern]="cellphonePattern">
      <span matSuffix *ngIf="this.contactForm.value['cellphone']" class="i-validator"></span>
      <mat-error [ngClass]="font?.size">{{ getErrorMessage('cellphone') | translate }}</mat-error>
    </mat-form-field>
    <p class="subtitle" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.contactUs.form.controls.products.title | translate"></p>
    <mat-form-field floatLabel="always" [ngClass]="font?.size">
      <mat-label [innerHTML]="pageContent?.sections.contactUs.form.controls.products.label | translate"></mat-label>
      <mat-select formControlName="product" [ngClass]="font?.size" [value]="selectedProduct" required>
        <mat-option *ngFor="let product of products" [value]="product.name">{{ product.name  | translate }}</mat-option>
      </mat-select>
      <div class="input-error" [ngClass]="font?.size">{{ getErrorMessage('product') | translate }}</div>
    </mat-form-field>
    <div class="disclaimer" [ngClass]="font?.size">
      <p>{{ pageContent?.sections.contactUs.form.captcha.paragraph | translate }}
        <a href="https://policies.google.com/privacy">{{ pageContent?.sections.contactUs.form.captcha.privacy | translate }}</a> {{ pageContent?.sections.contactUs.form.captcha.andText | translate }}
        <a href="https://policies.google.com/terms">{{ pageContent?.sections.contactUs.form.captcha.conditions | translate }}</a>{{ pageContent?.sections.contactUs.form.captcha.googleAplican | translate }}</p>
    </div>
    <button type="submit" class="button primary" [ngClass]="font?.size" [disabled]="contactForm.invalid"
      [innerHTML]="pageContent?.sections.contactUs.form.buttons.send | translate"></button>
  </form>
</div>