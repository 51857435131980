import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseURL;
  }

  public unsubscribe(email: string, entityId: string) {
    return this.http.post(`${this.baseUrl}/unsubscribe-email`, { email, entityId })
      .pipe(catchError(error => throwError(error)));
  }

  public checkSubscription(entityId: string) {
    return this.http.post(`${this.baseUrl}/check-subscription`, { entityId })
      .pipe(catchError(error => throwError(error)));
  }
}
