import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ACCESS_KEY_ROUTE,
  ACCESS_KEY_ROUTE_OLD,
  APV_ROUTE,
  LANDING_PRODUCTS,
  SAVINGS_ACCOUNT_ROUTE,
  SECURITY_KEY_ROUTE
} from '@constants/constants';
import { LANDING_CONTENT } from '@constants/landing.constants';
import { Section } from '@interfaces/landing.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public pageContent = LANDING_CONTENT;
  public products: Array<Section> = LANDING_PRODUCTS;
  public activeProduct: Section = this.products[3];
  public hideContent = true;

  constructor(
    public font: FontService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  get conditions(): string {
    const dict = {
      cuenta2: this.pageContent.sections.savingAccount.conditions,
      apv: this.pageContent.sections.apv.conditions,
    };
    return dict[this.activeTab];
  }

  get activeTab(): string { return this.activeProduct.route; }
  get isSavingsAccount(): boolean { return this.activeTab === SAVINGS_ACCOUNT_ROUTE; }
  get isApv(): boolean { return this.activeTab === APV_ROUTE; }
  get isSecurityKey(): boolean { return this.activeTab === SECURITY_KEY_ROUTE; }
  get isAccessKey(): boolean { return this.activeTab === ACCESS_KEY_ROUTE; }

  public ngOnInit() {
    this.setActiveProduct();
  }

  public openTab(product: Section) {
    const prefix = this.translate.currentLang + '/';
    this.router.navigateByUrl(prefix + product.route);
    this.activeProduct = product;
  }

  private setActiveProduct() {
    const productRoute = this.router.url.split('/').pop();
    const openedProduct = this.products.find(product => product.route === productRoute);
    const isOldClavesPath = this.router.url === `/${ ACCESS_KEY_ROUTE_OLD }`;
    this.openTab(isOldClavesPath ? this.products[3] : openedProduct ? openedProduct : this.products[0]);
  }
}
