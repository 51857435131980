import { Injectable } from '@angular/core';
import { clean } from 'rut.js';

@Injectable()
export class Util {
  constructor() {
  }

  public cleanRut(rut: string) {
    return clean(rut);
  }

  public setPassword(rut: string) {
    const cleanRut = this.cleanRut(rut);
    return cleanRut.slice(0, 4);
  }

  private addRegionsAccents(text: string): string {
    text = text.replace('Region', 'Región');
    text = text.replace('eptima', 'éptima');
    text = text.replace('ecima', 'écima');
    return text;
  }

  private addCommunesAccents(text: string): string {
    text = text.replace('Camina', 'Camiña');
    text = text.replace('Maria', 'María');
    text = text.replace('Ollague', 'Ollagüe');
    text = text.replace('Chanaral', 'Chañaral');
    text = text.replace('Copiapo', 'Copiapó');
    text = text.replace('Combarbala', 'Combarbalá');
    text = text.replace('Rio ', 'Río ');
    text = text.replace('Vicuna', 'Vicuña');
    text = text.replace('Concon', 'Concón');
    text = text.replace('Fernandez', 'Fernández');
    text = text.replace('Olmue', 'Olmué');
    text = text.replace('Puchuncavi', 'Puchuncaví');
    text = text.replace('Quilpue', 'Quilpué');
    text = text.replace('Valparaiso', 'Valparaíso');
    text = text.replace('Vina ', 'Viña ');
    text = text.replace('Chepica', 'Chépica');
    text = text.replace('Donihue', 'Doñihue');
    text = text.replace('Machali', 'Machalí');
    text = text.replace('Requinoa', 'Requínoa');
    text = text.replace('Colbun', 'Colbún');
    text = text.replace('Constitucion', 'Constitución');
    text = text.replace('Curico', 'Curicó');
    text = text.replace('Hualane', 'Hualañé');
    text = text.replace('Licanten', 'Licantén');
    text = text.replace('Longavi', 'Longaví');
    text = text.replace('Vichuquen', 'Vichuquén');
    text = text.replace('Bío', 'Bio');
    text = text.replace('Canete', 'Cañete');
    text = text.replace('Concepcion', 'Concepción');
    text = text.replace('Hualpen', 'Hualpén');
    text = text.replace('Alamos', 'Álamos');
    text = text.replace('Angeles', 'Ángeles');
    text = text.replace('Mulchen', 'Mulchén');
    text = text.replace('Barbara', 'Bárbara');
    text = text.replace('Tirua', 'Tirúa');
    text = text.replace('Tome', 'Tomé');
    text = text.replace('Curacautin', 'Curacautín');
    text = text.replace('Pitrufquen', 'Pitrufquén');
    text = text.replace('Pucon', 'Pucón');
    text = text.replace('Puren', 'Purén');
    text = text.replace('Tolten', 'Toltén');
    text = text.replace('Traiguen', 'Traiguén');
    text = text.replace('Vilcun', 'Vilcún');
    text = text.replace('Chaiten', 'Chaitén');
    text = text.replace('Cochamo', 'Cochamó');
    text = text.replace('Velez', 'Vélez');
    text = text.replace('Futaleufu', 'Futaleufú');
    text = text.replace('Hualaihue', 'Hualaihué');
    text = text.replace('Maullin', 'Maullín');
    text = text.replace('Puqueldon', 'Puqueldón');
    text = text.replace('Queilen', 'Queilén');
    text = text.replace('Quellon', 'Quellón');
    text = text.replace('Aisen', 'Aisén');
    text = text.replace('Ibanez', 'Ibáñez');
    text = text.replace('Antartica', 'Antártica');
    text = text.replace('Union', 'Unión');
    text = text.replace('Mafil', 'Máfil');
    text = text.replace('Chillan', 'Chillán');
    text = text.replace('Niquen', 'Ñiquén');
    text = text.replace('Quillon', 'Quillón');
    text = text.replace('Ranquil', 'Ránquil');
    text = text.replace('Fabian', 'Fabián');
    text = text.replace('Nicolas', 'Nicolás');
    text = text.replace('Alhue', 'Alhué');
    text = text.replace('Conchali', 'Conchalí');
    text = text.replace('Curacavi', 'Curacaví');
    text = text.replace('Estacion', 'Estación');
    text = text.replace('Maipu', 'Maipú');
    text = text.replace('Nunoa', 'Ñuñoa');
    text = text.replace('Penaflor', 'Peñaflor');
    text = text.replace('Penalolen', 'Peñalolén');
    text = text.replace('Joaquin', 'Joaquín');
    text = text.replace('Jose ', 'José ');
    text = text.replace('Ramon', 'Ramón');
    return text;
  }

  public checkSpelling(regions) {
    regions.forEach(region => {
      region.description = this.addRegionsAccents(region.description);
      region.communes.forEach(commune => commune.description = this.addCommunesAccents(commune.description));
    });
    return regions;
  }
}
