import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ROUTES_WITH_RECAPTCHA_VALIDATION, RECAPTCHA_ACTION_KEY } from '@constants/constants';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  constructor(
    public router: Router,
    private recaptchaService: ReCaptchaV3Service
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches = ROUTES_WITH_RECAPTCHA_VALIDATION.filter(
      (routes) => request.url.includes(routes.route) && routes.method === request.method
    );
    if (matches.length === 1) {
      return from(this.getRecaptchaToken(RECAPTCHA_ACTION_KEY))
      .pipe(
        switchMap((recaptchaToken: string) => {
          const requestClone = request.clone({
            body: {
              ...request.body,
              recaptchaToken
            },
          });
          return next.handle(requestClone);
        })
       );
    }
    return next.handle(request);
  }

  private getRecaptchaToken(actionKey: string) {
    return this.recaptchaService.execute(actionKey);
  }
}
