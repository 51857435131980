<div class="container">
  <div class="section-banner">
    <app-banner [banner]="activeProduct.banner"></app-banner>
  </div>
  <div class="tabs">
    <app-tab-navigation [tabs]="products" [activeTab]="activeProduct" (tabSelected)="openTab($event)"></app-tab-navigation>
  </div>
  <div class="landing-container general-container">
    <button *ngIf="hideContent" class="see-more button primary" [ngClass]="font?.size" (click)="hideContent = false">
     {{ pageContent?.sections.actions.seeMore | translate }}
      <div class="i-arrow-down-white"></div>
    </button>
    <button *ngIf="!hideContent" class="see-more button primary" [ngClass]="font?.size" (click)="hideContent = true">
      {{ pageContent?.sections.actions.seeLess | translate }}
       <div class="i-arrow-up-white"></div>
     </button>
    <div class="tab-content {{ hideContent ? 'hide' : 'show' }}">
      <div class="content-body">
        <h2 class="title" [ngClass]="font?.size">{{ activeProduct.subtitle | translate }}</h2>
        <ng-container *ngIf="isSavingsAccount">
          <p class="description" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.savingAccount.description | translate">
            <a href="https://www.planvital.cl/" class="link" [ngClass]="font?.size"
              [innerHTML]="pageContent?.careChannels.pv | translate"></a></p>
          <p class="subtitle" [ngClass]="font?.size"
            [innerHTML]="pageContent?.sections.savingAccount.questions.benefits.question | translate"></p>
            <div class="benefits list">
              <app-card icon="i-money-back" [description]="pageContent?.sections.savingAccount.questions.benefits.answer.freeDrafts"></app-card>
              <app-card icon="i-taxs" [description]="pageContent?.sections.savingAccount.questions.benefits.answer.taxBenefits"></app-card>
              <app-card icon="i-distribution" [description]="pageContent?.sections.savingAccount.questions.benefits.answer.multifunds"></app-card>
            </div>
        </ng-container>
        <ng-container *ngIf="isApv">
          <p class="description" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.description | translate"></p>
          <p class="subtitle" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.questions.benefits.question | translate"></p>
          <div class="benefits list">
            <app-card icon="i-money-back" [description]="pageContent?.sections.apv.questions.benefits.answer.commission"></app-card>
            <app-card icon="i-pig-red" [description]="pageContent?.sections.apv.questions.benefits.answer.bonus"></app-card>
            <app-card icon="i-dollar" [description]="pageContent?.sections.apv.questions.benefits.answer.backup"></app-card>
            <app-card icon="i-bar-red" [description]="pageContent?.sections.apv.questions.benefits.answer.pension"></app-card>
          </div>
          <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.indication | translate"></p>
          <div class="regimes">
            <div class="regimen">
              <div class="type" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.regimes.a.title | translate"></div>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.regimes.a.description | translate"></p>
            </div>
            <div class="regimen">
              <div class="type" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.regimes.b.title | translate"></div>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.apv.regimes.b.description | translate"></p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isSecurityKey">
          <p class="subtitle" [ngClass]="font?.size"
            [innerHTML]="pageContent?.sections.securityKey.questions.passwordFunctionality.question | translate"></p>
          <p class="text" [ngClass]="font?.size"
            [innerHTML]="pageContent?.sections.securityKey.questions.passwordFunctionality.answer | translate"></p>
          <p class="subtitle" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.securityKey.questions.getPassword.question | translate">
          </p>
          <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.securityKey.questions.getPassword.answer | translate">
          </p>
          <ul class="list">
            <li>
              <p class="text"><a href="https://www.planvital.cl/" class="link" [ngClass]="font?.size"
                  [innerHTML]="pageContent?.careChannels.pv | translate"></a></p>
            </li>
            <li>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.careChannels.contactCenter | translate"></p>
            </li>
            <li>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.careChannels.app | translate"></p>
            </li>
            <li>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.careChannels.socialNetworks | translate"></p>
            </li>
          </ul>
          <p class="text" [ngClass]="font?.size"
            [innerHTML]="pageContent?.sections.securityKey.questions.getPassword.indication | translate"></p>
        </ng-container>
        <ng-container *ngIf="isAccessKey">
          <p class="subtitle" [ngClass]="font?.size"
            [innerHTML]="pageContent?.sections.accessKey.questions.passwordFunctionality.question | translate"></p>
          <p class="text" [ngClass]="font?.size"
            [innerHTML]="pageContent?.sections.accessKey.questions.passwordFunctionality.answer | translate"></p>
          <p class="subtitle" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.accessKey.questions.getPassword.question | translate">
          </p>
          <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.sections.accessKey.questions.getPassword.answer | translate"></p>
          <ul class="list">
            <li>
              <p class="text"><a href="https://www.planvital.cl/" class="link" [ngClass]="font?.size"
                  [innerHTML]="pageContent?.careChannels.pv | translate"></a></p>
            </li>
            <li>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.careChannels.contactCenter | translate"></p>
            </li>
            <li>
              <p class="text" [ngClass]="font?.size" [innerHTML]="pageContent?.careChannels.socialNetworks | translate"></p>
            </li>
          </ul>
        </ng-container>
        <p class="terms desktop" [ngClass]="font?.size" [innerHTML]="conditions | translate"></p>
      </div>
    </div>
    <app-contact-form [selectedProduct]="activeProduct.name"></app-contact-form>
    <p class="terms mobile" [ngClass]="font?.size" [innerHTML]="conditions | translate"></p>
  </div>
</div>
