import { Component } from '@angular/core';
import { CERTIFICATES, FOOTER_CONTENT } from '@constants/footer.constans';
import { PLANVITAL_SITE, SOCIAL_MEDIA, SITES_PV, SITES_INTEREST, SITES_LANDING, PLANVITAL_PHONE } from '@constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public pageContent = FOOTER_CONTENT;
  public planvitalSite = PLANVITAL_SITE;
  public socialMedia = SOCIAL_MEDIA;
  public sitesPlanVital = SITES_PV;
  public sitesInterest = SITES_INTEREST;
  public sitesLanding = SITES_LANDING;
  public certificates = CERTIFICATES;
  public contactCenterNumber = PLANVITAL_PHONE;

  constructor(
    public translate: TranslateService,
    public font: FontService,
    public navigate: NavigationService
  ) {}
}
