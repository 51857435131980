import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@interfaces/dialogData';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {

  constructor(
    public font: FontService,
    public dialogRef: MatDialogRef<GenericModalComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }

  public dialogClose() {
    this.dialogRef.close();
  }

  public primaryAction() {
    this.dialogRef.close('primaryButtonPressed');
  }

  public secondaryAction() {
    this.dialogRef.close('secondaryButtonPressed');
  }

}
